<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            @onChangeData="onChangeData"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      routeSheetOpened: false,
      item: null,
      headers: [
        {
          value: 'routeSheetNumber', sortable: true, text: '№', type: 'defaultItem', width: 70,
        },
        {
          value: 'createdRouteSheetDate', sortable: true, text: 'Дата маршрутного листа', type: 'defaultItem', width: 150,
        },
        {
          value: 'orderId', sortable: false, text: 'Номер заказа', type: 'defaultItem',
        },
        {
          value: 'courierName', sortable: false, text: 'Курьер', type: 'defaultItem',
        },
        {
          value: 'address', sortable: false, text: 'Адрес доставки', type: 'defaultItem', width: 200,
        },
        {
          value: 'deliveryDate', sortable: true, text: 'Дата и время доставки курьером', type: 'defaultItem', width: 200,
        },
        {
          value: 'statusRouteSheet', sortable: false, text: 'Статус', type: 'enums', enums: 'RouteSheetStatusEnums',
        },
        {
          value: 'reason', sortable: false, text: 'Причина переназначения', type: 'defaultItem',
        },
      ],
      data: [],
      config: {
        add: false,
        search: true,
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: false,
        },
        {
          id: 'FINISHED', name: 'Выполнено', type: 'status', active: false,
        },
        {
          id: 'ON_DELIVERY', name: 'Выполняется', type: 'status', active: false,
        },
        {
          id: 'NEW', name: 'Новый', type: 'status', active: false,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      url: '/route-sheets/statistic',
      options: {
        page: 1,
        size: 10,
        sort: 'createdRouteSheetDate,desc',
      },
    };
  },
  async created() {
    // await this.getRouteSheetStatuses();
    this.onChangeData(this.$route.query);
  },
  methods: {
    // getRouteSheetStatuses() {
    //   return this.$routeSheets.get('/route-sheet/status').then((response) => {
    //     if (response && response.content) {
    //       response.content.forEach((i) => {
    //         this.availableFilters.push({
    //           id: i.code,
    //           name: i.nameRu,
    //           type: 'status',
    //           active: false,
    //         });
    //       });
    //     }
    //   });
    // },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getDelivery();
    },
    getDelivery() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$routeSheets.get(formattedUrl, {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          SearchText: params.search,
          Status: params.statuses,
          DeliveryDateStart: params.createdDateRange && params.createdDateRange.split(',')[0],
          DeliveryDateFinish: params.createdDateRange && params.createdDateRange.split(',')[1],
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = response.recordsFiltered > response.pageSize
          ? Math.ceil(response.recordsFiltered / response.pageSize)
          : 1;
        this.data = response.content.map((i) => ({
          ...i,
          createdRouteSheetDate: this.$moment(i.createdRouteSheetDate).format('DD.MM.YYYY HH:mm'),
          deliveryDate: i.deliveryDate && this.$moment(i.deliveryDate).format('DD.MM.YYYY HH:mm'),
        }));
        this.data = [...this.data];
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style scoped>

</style>
